import React from "react";
import styled from "styled-components";
import { QuotationFormPdfDownload } from "./QuotationFormPdfDownload";
import * as Cart from "../Contexts/CartContext";
import * as Ant from "antd";
import { Context } from "../AppContext";
const constants = require("../constants");

function PdfDownloadButton({ order }) {
  const [pdfReady, setPdfReady] = React.useState(false);
  const [spec, setSpec] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [cartData, setCartData] = React.useState(null);
  const app = React.useContext(Context);

  React.useEffect(() => {
    let cartData = JSON.parse(order.cart);
    setCartData(cartData);
  }, [order]);

  const fetchSpec = React.useCallback(async () => {
    if (cartData) {
      setLoading(true);
      try {
        let spec = await app.actions.getSpec(cartData.version);
        setSpec(spec);
        setPdfReady(true);
      } catch (err) {
        Ant.message.error("下載估價單，發生取得規格錯誤。");
      }
      setLoading(false);
    }
  }, [cartData]);

  return (
    <div style={{ marginRight: "8px" }}>
      <Ant.Spin spinning={loading}>
        <Button
          onClick={() => {
            if (pdfReady) {
              //標示為已下載估價單
              Cart.Actions.markOrderHasDownloadQuotationForm(order.id, true);
            } else {
              fetchSpec();
            }
          }}
        >
          {pdfReady ? (
            <QuotationFormPdfDownload
              order={order}
              spec={spec}
              style={{ color: "#9B9B9B", textDecoration: "none" }}
            />
          ) : (
            "下載估價單"
          )}
        </Button>
      </Ant.Spin>
    </div>
  );
}

const Button = styled.div`
  padding: 10px 16px;
  height: 44px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 12px;
  text-align: center;
  background-color: ${constants.buttons.background.normal.outline};
  color: ${constants.buttons.background.normal.primary};
  border: 1px solid ${constants.buttons.background.normal.primary};
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background-color: ${constants.buttons.background.hover.outline};
  }

  ${props => props.css}
`;

export default PdfDownloadButton;
