import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { getCalculation } from "../Utils/OrderUtil";

const PdfCartItemTable = ({ cart }) => {
  let {
    items_amount, //商品金額（未稅）
    items_tax, //商品金額（稅）
    discount, //活動折抵（未稅）
    discount_tax, //活動折抵（稅）
    fee, //運費(未稅)
    fee_tax, //運費（稅）
    bonus, //紅利（未稅）
    bonus_tax, //紅利（稅）
    feedback_bonus, //此訂單將會送的紅利
    amount, //總計(含稅)
  } = getCalculation(cart.calculations);
  return (
    <>
      <View style={styles.row}>
        <Text
          style={{
            fontSize: 10,
            flex: 5,
            padding: 2,
          }}
        >
          商品小計(含稅)
        </Text>
        <Text style={styles.cell}>{items_amount + items_tax}</Text>
      </View>
      <View style={styles.row}>
        <Text
          style={{
            fontSize: 10,
            flex: 5,
            padding: 2,
          }}
        >
          運費（含稅）
        </Text>
        <Text style={styles.cell}>{fee + fee_tax}</Text>
      </View>
      <View style={styles.row}>
        <Text
          style={{
            fontSize: 10,
            flex: 5,
            padding: 2,
          }}
        >
          活動折扣（含稅）
        </Text>
        <Text style={styles.cell}>{discount + discount_tax}</Text>
      </View>
      <View style={styles.row}>
        <Text
          style={{
            fontSize: 10,
            flex: 5,
            padding: 2,
          }}
        >
          總計(含稅)
        </Text>
        <Text style={styles.cell}>{amount}</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#b9b9b9",
    borderBottomWidth: 1,
  },

  cell: {
    fontSize: 10,
    flex: 1,
    padding: 2,
    borderRightColor: "#b9b9b9",
    borderRightWidth: 1,
  },
});

export default PdfCartItemTable;
