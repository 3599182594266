import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ExpandLess, ExpandMore } from "@styled-icons/material";
const Constants = require("../../constants");

const Expander = ({
  title,
  children,
  StatusComponent,
  expanderNumber,
  variant = "default",
  ProductImg,
  ProductInfo,
  Tag,
  contentInnerStyle,
  containerStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [isOpen]);

  const renderHeader = () => {
    if (variant === "default") {
      return (
        <>
          <LeftSection>
            <Title>{title}</Title>
            <div style={{ display: "flex", alignItems: "center" }}>
              {expanderNumber && (
                <ExpanderNumber># {expanderNumber}</ExpanderNumber>
              )}
              {isOpen ? <ExpandLess size={20} /> : <ExpandMore size={20} />}
            </div>
          </LeftSection>
          {StatusComponent && <StatusComponent />}
        </>
      );
    } else if (variant === "compact") {
      return (
        <>
          <RightSection>
            {ProductImg}
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Title variant={variant}>{title}</Title>
                {isOpen ? <ExpandLess size={20} /> : <ExpandMore size={20} />}
              </div>
              {ProductInfo}
            </div>
            {Tag}
          </RightSection>
        </>
      );
    }
  };

  return (
    <ExpanderContainer variant={variant} style={containerStyle}>
      <ExpanderHeader variant={variant} onClick={() => setIsOpen(!isOpen)}>
        {renderHeader()}
      </ExpanderHeader>
      <ExpanderContent
        isOpen={isOpen}
        ref={contentRef}
        style={{ height: `${contentHeight}px` }}
      >
        <ContentInner style={contentInnerStyle}>
          <div>{children}</div>
        </ContentInner>
      </ExpanderContent>
    </ExpanderContainer>
  );
};

const ExpanderContainer = styled.div`
  border: ${props =>
    props.variant === "compact" ? "none" : "1px solid #d8dbdf"};
  border-radius: 16px;
  margin-top: 16px;
  overflow: hidden;
  ${props => props.style}
`;

const ExpanderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.variant === "compact" ? "0 0 24px 0" : "24px")};
  background-color: #fff;
  cursor: pointer;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    position: relative;
    padding-top: 54px;
    width: 100%;
  }
`;

const Title = styled.span`
  font-size: ${props => (props.variant === "compact" ? "20px" : "14px")};
  font-weight: ${props => (props.variant === "compact" ? "bold" : "400")};
  color: #4a4e5a;
  margin-bottom: ${props => (props.variant === "compact" ? "0px" : "4px")};
`;

const ExpanderNumber = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #25272c;
  margin-right: 4px;
  line-height: 30px;
`;

const ExpanderContent = styled.div`
  background-color: white;
  transition: height 0.3s ease-out;
  overflow: hidden;
`;

const ContentInner = styled.div`
  padding: 0 24px 24px;
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    padding: 0;
  }
  > div {
    word-wrap: break-word;
    overflow-wrap: break-word;
    background-color: #f8f8f8;
    padding: 24px;
    border-radius: 16px;
    @media only screen and (max-width: ${Constants.breakpoints.md}px) {
      background-color: unset;
    }
  }
  ${props => props.style}
`;

export default Expander;
