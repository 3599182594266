import React, {
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { RowText } from "../../Widgets";
import { Input, message } from "antd";
import RefundInfoForm from "./RefundInfoForm";
import * as Cart from "../../Contexts/CartContext";
import moment from "moment";

const Constants = require("../../constants");

const RETURN_STATUS = {
  pending: "等待處理",
  supplying: "補印件中",
  supplement_completed: "已完成補印件",
  returning: "退款處理中",
  return_completed: "售後服務完成",
  rejected: "退貨條件不符，已拒絕退貨",
};

const REFUND_STATUS = {
  waiting: "退款處理中",
  success: "退款已完成",
  failure: "退款已取消",
};

const REFUND_TYPE = {
  partial: "部分金額轉帳退款",
  transfer_full: "全額轉帳退款",
  full: "全額信用卡刷退",
};

function AfterSales({ order, orderItems }) {
  const [returnApps, setReturnApps] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedRefund, setEditedRefund] = useState(null);

  const { orderItem, refund, returnApp } = useMemo(() => {
    return {
      orderItem: orderItems && orderItems.length > 0 && orderItems[0],
      refund: Array.isArray(refunds) && refunds.length > 0 ? refunds[0] : null,
      returnApp:
        Array.isArray(returnApps) && returnApps.length > 0
          ? returnApps[0]
          : null,
    };
  }, [orderItems, refunds, returnApps]);

  const getRefunds = useCallback(async () => {
    try {
      let resp = await Cart.Actions.getRefundsByOrderId(order.id);
      setRefunds(resp.results);
    } catch (ex) {
      console.warn(ex);
    }
  }, [order]);

  const getReturnApps = useCallback(async () => {
    try {
      let resp = await Cart.Actions.getReturnAppsByOrderId(order.id);
      setReturnApps(resp.results);
    } catch (ex) {
      console.warn(ex);
    }
  }, [order]);

  useEffect(() => {
    getRefunds();
    getReturnApps();
  }, [getRefunds, getReturnApps]);

  if (!order) {
    return null;
  }

  if (["credit", "extra", "monthly"].includes(order.order_type)) {
    return null;
  }

  if (order.payment_status !== "success") {
    return null;
  }

  const Tag = () => {
    if (
      returnApp?.status === "return_completed" ||
      refund?.status === "failure"
    ) {
      return (
        <StyledTag isRefunded={refund?.status === "success"}>
          {REFUND_STATUS[refund?.status]}
        </StyledTag>
      );
    }
    return (
      <StyledTag isRefunded={refund?.status === "success"}>
        {RETURN_STATUS[returnApp?.status]}
      </StyledTag>
    );
  };

  const StyledRowText = props => (
    <RowText
      labelStyle={{ color: "#6B7280" }}
      containerStyle={{ marginBottom: "8px" }}
      {...props}
    />
  );

  const handleEdit = () => {
    setIsEditing(true);
    setEditedRefund(refund);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedRefund(null);
  };

  const handleConfirm = async () => {
    if (isFormValid()) {
      try {
        await Cart.Actions.editRefund({
          id: refund.id,
          ...editedRefund,
        });
        getRefunds();
        setIsEditing(false);
      } catch (err) {
        console.warn(err);
        message.warning("異常錯誤");
      }
    } else {
      message.warning("欄位未填寫完畢");
    }
  };

  const isFormValid = () => {
    return (
      editedRefund &&
      editedRefund.bank_code &&
      editedRefund.bank_name &&
      editedRefund.bank_account_name &&
      editedRefund.bank_account
    );
  };

  const handleRefundChange = updatedRefund => {
    setEditedRefund(updatedRefund);
  };

  return (
    <Wrapper>
      <Header>
        <IconWrapper>
          <img src="./images/order/afterSales.svg" alt="" />
        </IconWrapper>
        <h3>售後申請</h3>
        {Tag()}
      </Header>
      <Content>
        {isEditing ? (
          <RefundInfoForm initialData={refund} onChange={handleRefundChange} />
        ) : returnApp && !refund ? (
          <Container>
            <div>
              <h4>售後申請資訊</h4>
              <StyledRowText
                label="退貨原因"
                value={returnApp.rejected_reason}
              />
              <StyledRowText label="退貨備註" value={returnApp.note} />
              <StyledRowText
                label="建立時間"
                value={moment(returnApp.created).format("YYYY-MM-DD HH:mm")}
              />
            </div>
          </Container>
        ) : (
          <Container>
            <div>
              <h4>退款資訊</h4>
              <StyledRowText
                label="退款方式"
                value={
                  order.payment_type === "credits" ? (
                    <>
                      {
                        {
                          partial: "部分點數退還",
                          transfer_full: "全額點數退還",
                          full: "全額點數退還",
                        }[refund?.refund_type]
                      }
                    </>
                  ) : (
                    <>{REFUND_TYPE[refund?.refund_type]}</>
                  )
                }
              />
              <StyledRowText
                label="退款原因"
                value={returnApp?.rejected_reason}
              />
              <StyledRowText label="人員備註" value={returnApp?.note} />
              <StyledRowText label="退款金額" value={`NT$${refund?.amount}`} />
            </div>
            {["partial", "transfer_full"].includes(refund?.refund_type) &&
              order.payment_type !== "credits" && (
                <div>
                  <h4>收款帳戶</h4>
                  <StyledRowText label="銀行代碼" value={refund?.bank_code} />
                  <StyledRowText label="分行名稱" value={refund?.bank_name} />
                  <StyledRowText
                    label="收款戶名"
                    value={refund?.bank_account_name}
                  />
                  <StyledRowText
                    label="收款帳號"
                    value={refund?.bank_account}
                  />
                </div>
              )}
          </Container>
        )}
      </Content>
      {["partial", "transfer_full"].includes(refund?.refund_type) &&
        order.payment_type !== "credits" &&
        order.display_state !== "refunded" &&
        refund?.status !== "failure" && (
          <Footer>
            {!refund?.bank_account && (
              <p>退款方式為轉帳退款，請填寫收款帳戶以利人員完成退款</p>
            )}
            {isEditing ? (
              <>
                <StyledButton className="cancleBtn" onClick={handleCancel}>
                  取消
                </StyledButton>
                <StyledButton
                  className="confirmBtn"
                  onClick={handleConfirm}
                  disabled={!isFormValid()}
                >
                  確定
                </StyledButton>
              </>
            ) : (
              <StyledButton onClick={handleEdit}>填寫收款訊息</StyledButton>
            )}
          </Footer>
        )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 16px;
  background: #fff;
  margin-bottom: 24px;
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    border-radius: 0;
    > * {
      padding: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #d8dbdf;
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #fff4ed;
  border-radius: 8px;
  margin-right: 12px;
`;

const Content = styled.div`
  padding: 24px;
`;

const Container = styled.div`
  display: flex;
  h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 24px;
  border-top: 1px solid #d8dbdf;
  p {
    color: #9b9b9b;
    margin-bottom: 8px;
    flex: 0 0 100%;
    width: 100%;
  }
`;

const StyledTag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  border-radius: 100px;
  background-color: ${props => (props.isRefunded ? "#F0FAF0" : "#F8F8F8")};
  color: ${props => (props.isRefunded ? "#41AE49" : "#000")};
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
`;

const StyledButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: ${Constants.buttons.background.normal.primary};
  border-radius: 12px;
  cursor: pointer;
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    width: 96px;
    height: 44px;
  }
  &.cancleBtn {
    background-color: transparent;
    color: #5b616e;
    margin-right: 24px;
  }
  &.confirmBtn {
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    background-color: ${props =>
      props.disabled ? "#EDEEF1" : Constants.buttons.background.normal.primary};
    color: ${props => (props.disabled ? "#8E95A2" : "white")};
  }
`;

const StyledInput = styled(Input)`
  margin-bottom: 8px;
`;

export default AfterSales;
