/* global PAYUNI_SDK */
import React from "react";
import styled from "styled-components";
const Constants = require("../../../constants");

function Credit() {
  return (
    <CreditWrapper>
      <StyledInputWrapper id="put_card_no" />
      <StyledInputWrapper id="put_card_exp" />
      <StyledInputWrapper id="put_card_cvc" />
    </CreditWrapper>
  );
}

const CreditWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 24px;
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    flex-wrap: wrap;
  }
`;

const StyledInputWrapper = styled.div`
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid #c1c7cd;
  width: 100%;
  &#put_card_no,
  &#put_card_exp,
  &#put_card_cvc {
    height: 44px;
    @media only screen and (max-width: ${Constants.breakpoints.md}px) {
      height: 48px;
    }
  }
  &#put_card_no {
    flex: auto;
  }
  &#put_card_exp,
  &#put_card_cvc {
    max-width: 120px;
    @media only screen and (max-width: ${Constants.breakpoints.md}px) {
      max-width: calc(50% - 8px);
      margin-top: 16px;
    }
  }
`;

export default Credit;
