import React from "react";
import * as Ant from "antd";

export default class UploadProgressModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "上傳中",
      num: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this._start();
    }
    if (
      (prevProps.show && !this.props.show) ||
      (!prevProps.show && this.props.show)
    ) {
      this.setState({ num: 0, message: "上傳中" });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    let { show } = this.props;
    let { num, message } = this.state;

    return (
      <Ant.Modal visible={show} footer={false} closable={false} width="200px">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p>{message}</p>
          <Ant.Progress percent={num} />
        </div>
      </Ant.Modal>
    );
  }

  _start = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      let num = this.state.num + 1;
      if (num >= 99) {
        this.setState({ message: "處理中，請耐心等候" });
        clearInterval(this.timer);
      }

      this.setState({ num });
    }, 1000);
  };
}
