import React from "react";
import { Block, Text } from "../../Widgets";
import styled from "styled-components";
const constants = require("../../constants");

function Calculation({ order }) {
  const { calculations } = JSON.parse(order.cart);

  const formatCurrency = amount => {
    return amount === 0 ? "免運" : `$ ${amount}`;
  };

  return (
    <StyledBlock
      title={"訂單明細"}
      size={`${constants.fontSize.md}px`}
      fontWeight={700}
      height={"30px"}
      lineHeight={"30px"}
      padding={"0px"}
      wrapperPadding={"24px"}
      wrapperPaddingMargin={"0 0 24px 0"}
      bg={constants.colors.background}
      color={constants.colors.text}
    >
      <PriceWrapper>
        <Text size="sm" color="#4A4E5A">
          商品金額
        </Text>
        <Text size="sm" color="#4A4E5A">{`$${calculations.items_amount +
          calculations.items_tax}`}</Text>
      </PriceWrapper>
      <PriceWrapper>
        <Text size="sm" color="#4A4E5A">
          運費
        </Text>
        <Text size="sm" color="#4A4E5A">
          {formatCurrency(calculations.fee + calculations.fee_tax)}
        </Text>
      </PriceWrapper>
      {calculations.bonus !== 0 && (
        <PriceWrapper>
          <Text size="sm" color="#4A4E5A">
            紅利折抵
          </Text>
          <Text size="sm" color="#4A4E5A">
            $ {calculations.bonus + calculations.bonus_tax}
          </Text>
        </PriceWrapper>
      )}
      {calculations.discount !== 0 && (
        <PriceWrapper>
          <Text size="sm" color="#4A4E5A">
            活動折抵
          </Text>
          <Text size="sm" color="#4A4E5A">
            $ {calculations.discount + calculations.discount_tax}
          </Text>
        </PriceWrapper>
      )}
      <PriceWrapper>
        <Text size="sm" color="#4A4E5A" weight="bold">
          總金額
        </Text>
        <Text size="sm" color="#4A4E5A" weight="bold">
          $ {calculations.amount}
        </Text>
      </PriceWrapper>
      <Divider />
      <PriceWrapper>
        <Text size="sm" color="#4A4E5A">
          付款方式
        </Text>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Text size="sm" color="#4A4E5A">
            {
              {
                credits: "點數付款",
                payuni: "統一付款 / ",
                neweb: "藍新付款 / ",
                offline: "臨櫃匯款",
                monthly: "月結付款",
              }[order.payment_type]
            }{" "}
            {(order.payment_type === "neweb" ||
              order.payment_type === "payuni") &&
              {
                credit: "信用卡付款",
                cvs_cod: "超商取貨付款",
                cvs: "超商繳費",
                atm: "ATM付款",
                web_atm: "網路ATM付款",
                barcode: "超商條碼繳費",
                line_pay: "Line Pay 付款",
              }[order.payment_subtype]}
          </Text>
          {order.payment_status === "success" && (
            <Text
              size="sm"
              weight="500"
              color="#41AE49"
              css={{ paddingLeft: "4px" }}
            >
              已付款
            </Text>
          )}
        </div>
      </PriceWrapper>
      <PriceWrapper>
        <Text size="sm" color="#4A4E5A">
          獲得紅利
        </Text>
        <Text size="sm" color="#4A4E5A">
          $ {calculations.feedback_bonus}
        </Text>
      </PriceWrapper>
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  @media only screen and (max-width: ${constants.breakpoints.md}px) {
    border-radius: 0;
    > * {
      padding: 0;
    }
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  &:first-child {
    margin-top: 16px;
  }
`;

const Divider = styled.hr`
  margin: 8px 0;
  background-color: #b6bac3;
  border: none;
  height: 1px;
`;

export default Calculation;
