import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Tooltip, message, Input, Button } from "antd";
import { Text } from "../../Widgets";
import * as Icon from "../../Widgets/Icon";
import * as Cart from "../../Contexts/CartContext";
import Expander from "./Expander";
import UploadFileButton from "../UploadFileButton";
import ProductConfigSummary from "../NewCart/ProductConfigSummary";
const Constants = require("../../constants");

const REVIEW_STATUS = {
  waiting: "未上傳",
  pending: "待審稿",
  reupload: "已補件",
  accepted: "通過",
  rejected: "未通過",
  void: "作廢",
};

const PORDUCTION_STATUS = {
  waiting: "等待中",
  pending: "-",
  preparing: "工單已交付",
  in_production: "製作中",
  completed: "印製完成",
  in_storage: "已入庫",
};

function Item({ isCustomItem, item, product, orderItem, onUpdate }) {
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (orderItem) {
      setNote(orderItem.note_2 || orderItem.latest_attachment?.note || "");
    }
  }, [orderItem]);

  const _editAttachment = useCallback(
    async ({ url }) => {
      await Cart.Actions.addAttatchment({
        order: orderItem.order,
        item_index: orderItem.item_index,
        url,
      });
    },
    [orderItem]
  );

  const submitNote = useCallback(async () => {
    setLoading(true);
    try {
      let result = await Cart.Actions.editOrderItem(orderItem.id, {
        note_2: note,
      });
      setNote(result.note_2);
      message.success("儲存成功");
      setShowNoteInput(false);
      setIsEditing(false);
    } catch (ex) {
      console.warn(ex);
      message.error("修改錯誤");
    }
    setLoading(false);
  }, [note, orderItem.id]);

  const toggleNoteInput = () => {
    setShowNoteInput(!showNoteInput);
    setIsEditing(!isEditing);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      submitNote();
      setIsEditing(false);
    }
  };

  const getReviewStatusTagStyle = () => {
    switch (orderItem.status) {
      case "pending":
      case "reupload":
        return {
          background: "#f8f8f8",
          color: "#25272c",
        };
      case "accepted":
        return {
          background: "#f0faf0",
          color: "#41ae49",
        };
      case "rejected":
        return {
          background: "#fef6f6",
          color: "#e2341d",
        };
      default:
        return {
          background: "#fff4ed",
          color: `${Constants.colors.highlight}`,
        };
    }
  };

  const getProductionStatusTagStyle = () => {
    switch (orderItem.production_status) {
      case "completed":
      case "in_storage":
        return {
          background: "#f0faf0",
          color: "#41ae49",
        };
      default:
        return {
          background: "#f8f8f8",
          color: "#25272c",
        };
    }
  };

  const ProductImg = () => (
    <StyledProductImgWrapper
      src={
        isCustomItem
          ? "./images/order/customize-order-product-image.svg"
          : item.image
      }
      alt="Product Img"
    />
  );

  const Info = () => (
    <InfoWrapper>
      <div>
        數量 <span>{item.quantity}</span>
      </div>
      <div>
        總價 <span>${item.amount}</span>
      </div>
    </InfoWrapper>
  );

  const Tag = () => {
    return orderItem && orderItem.status !== "accepted" ? (
      <StyledTag getTagStyle={getReviewStatusTagStyle()}>
        {REVIEW_STATUS[orderItem?.status]}
      </StyledTag>
    ) : (
      <StyledTag getTagStyle={getProductionStatusTagStyle()}>
        {PORDUCTION_STATUS[orderItem?.production_status]}
      </StyledTag>
    );
  };

  return (
    <Wrapper>
      <Expander
        variant="compact"
        title={item.name}
        ProductImg={<ProductImg />}
        ProductInfo={<Info />}
        Tag={<Tag />}
        containerStyle={{
          borderRadius: "0",
        }}
        contentInnerStyle={{
          padding: "0 0 24px",
        }}
      >
        <ProductConfigSummary product={product} item={item} viewOnly={true} />
      </Expander>
      {orderItem.status === "rejected" && (
        <StyledErrorNoteWrapper>
          <img src="./images/order/info-error.svg" alt="" />
          <div>
            {orderItem.reject_reason}
            {orderItem.note && (
              <>
                {orderItem.reject_reason && "，"}
                {orderItem.note}
              </>
            )}
          </div>
        </StyledErrorNoteWrapper>
      )}
      <UploadFileButton
        status={orderItem.status}
        paymentStatus={orderItem.payment_status}
        attachment={orderItem.latest_attachment}
        note={orderItem.note}
        onUpdate={onUpdate}
        editOrderItem={_editAttachment}
      />
      <Tooltip
        title="如需上傳多個檔案請將文件壓縮為 RAR / ZIP 格式上傳。檔案支援 Adobe Illustrator / Adobe photoshop、JPG、PDF 等格式。請勿將印製圖檔置�� PPT / Word / Excel 等文字編輯檔格式中上傳提供。"
        overlayInnerStyle={{
          backgroundColor: "#25272c",
          color: "#d8dBdf",
          border: "1px solid #40444c",
          borderRadius: "5px",
          padding: "8px 12px",
        }}
        placement="bottom"
      >
        <TooltipInner>
          <Text size="sm" color="#6b7280">
            檔案上傳說明
          </Text>
          <Icon.InfoCircle
            size={16}
            color={"#1c1b1f"}
            css={{ cursor: "pointer" }}
          />
        </TooltipInner>
      </Tooltip>
      <Divider />
      <NoteWrapper>
        <div onClick={toggleNoteInput}>
          {isEditing ? "編輯備註" : "製作備註"}
          {!isEditing && <img src="./images/order/edit.svg" alt="" />}
        </div>
        {showNoteInput ? (
          <NoteInputWrapper>
            <Input
              value={note}
              onChange={e => setNote(e.target.value)}
              onKeyPress={handleKeyPress}
              disabled={loading}
            />
            <Text
              size="sm"
              onClick={() => {
                submitNote();
                setIsEditing(false);
              }}
            >
              儲存
            </Text>
          </NoteInputWrapper>
        ) : (
          <NoteContent>{note || "暫無備註"}</NoteContent>
        )}
      </NoteWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #d8dbdf;
  background: #fff;
  margin-bottom: 16px;
`;

const StyledTag = styled.div`
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.getTagStyle.color};
  background: ${props => props.getTagStyle.background};
  margin: 0 0 0 auto;
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    position: absolute;
    top: 0;
  }
`;

const TooltipInner = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  cursor: pointer;
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: #d8dbdf;
  margin: 16px 0;
`;

const NoteWrapper = styled.div`
  > div {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #25272c;
    img {
      margin-left: 8px;
      cursor: pointer;
    }
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    font-size: 14px;
    color: "#4A4E5A";
    &:first-child {
      margin-right: 16px;
    }
    span {
      display: inline-block;
      font-weight: 500;
      margin-left: 4px;
    }
  }
`;

const NoteInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  div {
    position: absolute;
    right: 16px;
    font-size: 16px;
    color: ${Constants.colors.highlight};
    cursor: pointer;
  }
  .ant-input {
    border-radius: 6px;
    border: 1px solid #b6bac3;
    height: 48px;
  }
`;

const NoteContent = styled.div`
  font-size: 16px;
  color: #25272c;
  word-break: break-all;
  margin-top: 8px;
`;

const StyledProductImgWrapper = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 5px;
  margin-right: 24px;
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    width: 64px;
    height: 64px;
    flex: 0 0 64px;
    margin-right: 16px;
  }
`;

const StyledErrorNoteWrapper = styled.div`
  display: flex;
  line-height: normal;
  align-items: flex-start;
  background: #fef6f6;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 16px;
  img {
    width: 24px;
    flex: 0 0 24px;
    margin-right: 8px;
  }
`;

export default Item;
