import React, { Fragment } from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import { Button, TextArea } from "../Components/DialogWidgets";
import { Modal, Result, Button as AntButton, Spin } from "antd";
import styled from "styled-components";
import * as Icon from "../Components/Icon";

const options = [
  "訂單金額有誤 (例如：商品、運費金額等)",
  "商品數量下單錯誤",
  "商品款式下單錯誤",
  "其他原因",
];

class VoidOrderDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        void_reason: options[0],
        note: "",
      },
      loading: false,
      confirming: false,
    };
  }

  render() {
    let { closeDialog, show, cartData } = this.props;
    let { values, loading, confirming } = this.state;
    return (
      <StyledModal
        visible={show}
        title="取消訂單"
        onCancel={closeDialog}
        footer={null}
        closeIcon={
          <Icon.Close
            style={{ position: "absolute", right: 20, top: 20 }}
            size={20}
          />
        }
      >
        {confirming ? (
          <div className="content">
            <Result
              status="warning"
              title="是否取消此訂單"
              subTitle="取消訂單後，無法復原"
              extra={[
                <AntButton
                  key="cancel"
                  onClick={() => this.setState({ confirming: false })}
                >
                  取消
                </AntButton>,
                <AntButton
                  key="ok"
                  onClick={() => {
                    this._voidOrder();
                    this.setState({ confirming: false });
                  }}
                >
                  確定
                </AntButton>,
              ]}
            />
          </div>
        ) : (
          <>
            <div className="content">
              <div className="row">
                <ProductName>{cartData?.items[0].name}</ProductName>
                <StyledSelector
                  value={values.void_reason}
                  onChange={e => {
                    let value = e.target.value;
                    this.setState({
                      values: { ...values, void_reason: value },
                    });
                  }}
                >
                  {options.map(option => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </StyledSelector>
              </div>
              {values.void_reason === "其他原因" && (
                <div className="row">
                  <h4>其他原因</h4>
                  <TextArea
                    value={values.note}
                    onChange={e => {
                      this.setState({
                        values: { ...values, note: e.target.value },
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <StyledButton onClick={() => this.setState({ confirming: true })}>
                {loading ? <Spin /> : "取消訂單"}
              </StyledButton>
            </div>
          </>
        )}
      </StyledModal>
    );
  }

  _voidOrder = async () => {
    let { values } = this.state;
    let { void_reason, note } = values;
    let { appActions, order, closeDialog, refresh } = this.props;
    if (this.state.loading) {
      return;
    }

    if (void_reason === "其他原因") {
      void_reason = void_reason + " > " + note;
    }
    this.setState({ loading: true });

    try {
      await appActions.voidOrder({ id: order.id, void_reason });
      closeDialog();
      await refresh();
    } catch (ex) {
      // TODO: show error!
      console.error("void api error", ex);
    }
    this.setState({ loading: false });
  };
}

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-header {
    padding: 24px;
    border-radius: 16px 16px 0 0;
    border-bottom: none;
    .ant-modal-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
  }
  .ant-modal-body {
    padding: 0 24px 24px;
  }
`;

const ProductName = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const StyledSelector = styled.select`
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px solid #b6bac3;
  font-size: 16px;
  padding: 12px 16px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M16.59%208.59L12%2013.17%207.41%208.59%206%2010l6%206%206-6z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 24px auto;
  &:focus {
    outline: none;
  }
`;

const StyledButton = styled(Button)`
  width: 112px;
  height: 56px;
  font-size: 16px;
  border-radius: 12px;
  margin: 24px 0 0;
`;

export default connect(null, ActionCreator)(VoidOrderDialog);
