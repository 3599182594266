import React from "react";
import { Block, RowText } from "../../Widgets";
import { Button } from "antd";
import Expander from "./Expander";
import styled from "styled-components";
import moment from "moment";
import { LOVECODE_OPTIONS } from "../../dictionary";

const constants = require("../../constants");

const INVOICE_CATEGORY = {
  B2B: "三聯式發票",
  B2C: "二聯式發票",
};

const INVOICE_TYPE = {
  two_copies: "二聯式發票",
  three_copies: "三聯式發票",
  donate: "捐贈發票",
};

const TWO_COPIES_CODE_TYPES = {
  citizen_personal_certificate: "自然人憑證",
  mobile_vehicle: "手機載具",
  ezpay_vehicle: "ezPay 電子發票載具",
};

const StyledRowText = props => (
  <RowText
    labelStyle={{
      fontSize: "14px",
      color: "#40444C",
      width: "78px",
      marginRight: "4px",
    }}
    textStyle={{ fontSize: "14px", fontWeight: 500 }}
    {...props}
  />
);

function Invoice({ order, invoices }) {
  const cartData = JSON.parse(order.cart);
  const invoiceConfig = cartData?.config?.invoiceConfig || null;
  const invoicesData =
    invoices?.filter(invoice => invoice.invoice_status === "success") || [];
  const Tag = ({ invocie }) => {
    const wording = invocie ? "發票已開立" : "出貨後開立";

    return <StyledTag>{wording}</StyledTag>;
  };

  const getLoveCodeLabel = code => {
    const option = LOVECODE_OPTIONS.find(opt => opt.value === code);
    return option ? option.label : code;
  };

  const InvoiceConfigInfo = () => (
    <>
      <StyledRowText
        label="發票類型"
        value={INVOICE_TYPE[invoiceConfig.invoice_type]}
      />
      {invoiceConfig.invoice_type === "donate" && (
        <>
          <StyledRowText label="愛心碼" value={invoiceConfig.love_code} />
          <StyledRowText
            label="捐贈單位"
            value={getLoveCodeLabel(invoiceConfig.love_code)}
          />
        </>
      )}
      {invoiceConfig.invoice_type === "two_copies" && (
        <>
          <StyledRowText
            label="發票類型"
            value={TWO_COPIES_CODE_TYPES[invoiceConfig.invoice_subtype]}
          />
          {invoiceConfig.invoice_subtype !== "ezpay_vehicle" && (
            <StyledRowText
              label={`${
                TWO_COPIES_CODE_TYPES[invoiceConfig.invoice_subtype]
              }條碼`}
              value={
                invoiceConfig.invoice_subtype === "citizen_personal_certificate"
                  ? invoiceConfig.citizen_personal_certificate_code
                  : invoiceConfig.invoice_subtype === "mobile_vehicle"
                  ? invoiceConfig.mobile_vehicle_code
                  : ""
              }
            />
          )}
        </>
      )}
      {invoiceConfig.invoice_type === "three_copies" && (
        <>
          <StyledRowText label="公司統編" value={invoiceConfig.gui_number} />
          <StyledRowText label="公司抬頭" value={invoiceConfig.company_title} />
        </>
      )}
    </>
  );

  const InvoiceInfo = ({ invoice }) => {
    return (
      <>
        <StyledRowText
          label="發票類型"
          value={
            invoice.love_code
              ? "捐贈發票"
              : invoice.category === "B2B"
              ? "三聯式發票"
              : invoice.category === "B2C"
              ? "二聯式發票"
              : ""
          }
        />
        <StyledRowText label="發票號碼" value={invoice.invoice_number} />
        <StyledRowText
          label="發票日期"
          value={moment(invoice.created).format("YYYY-MM-DD HH:mm:ss")}
        />
        {invoice.category === "B2B" && (
          <>
            <StyledRowText label="統一編號" value={invoice.buyer_ubn} />
            <StyledRowText label="公司抬頭" value={invoice.company_title} />
          </>
        )}
        {invoice.category === "B2C" && (
          <>
            <StyledRowText
              label="載具類別"
              value={
                invoice.carrier_type === 0
                  ? "手機條碼載具"
                  : invoice.carrier_type === 1
                  ? "自然人憑證條碼載具"
                  : "ezPay 電子發票載具"
              }
            />
            <StyledRowText label="載具編號" value={invoice.carrier_num} />
          </>
        )}
        {invoice.love_code && (
          <>
            <StyledRowText label="愛心碼" value={invoice.love_code} />
            <StyledRowText
              label="捐贈單位"
              value={getLoveCodeLabel(invoice.love_code)}
            />
          </>
        )}
        <InvoiceReceiptButton
          href={`https://inv.ezpay.com.tw/Invoice_index/search_platform`}
          target="_blank"
        >
          下載發票證明聯
        </InvoiceReceiptButton>
      </>
    );
  };

  return (
    <StyledBlock
      title={"發票資訊"}
      size={`${constants.fontSize.md}px`}
      fontWeight={700}
      height={"30px"}
      lineHeight={"30px"}
      padding={"0px"}
      wrapperPadding={"24px"}
      bg={constants.colors.background}
      color={constants.colors.text}
    >
      {invoicesData.length > 0 ? (
        invoicesData.map((invoice, index) => (
          <Expander
            title="發票號碼"
            StatusComponent={() => <Tag invoice={invoice} />}
            expanderNumber={invoice.invoice_number}
          >
            <InvoiceInfo invoice={invoice} />
          </Expander>
        ))
      ) : (
        <Expander title="發票號碼" StatusComponent={() => <Tag />}>
          <InvoiceConfigInfo />
        </Expander>
      )}
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  @media only screen and (max-width: ${constants.breakpoints.md}px) {
    border-radius: 0;
    > * {
      padding: 0;
    }
  }
`;

const StyledTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  border-radius: 100px;
  background-color: #f8f8f8;
  color: #4a4e5a;
  font-size: 16px;
  font-weight: 500;
`;

const InvoiceReceiptButton = styled(Button)`
  font-weight: 500;
  color: ${constants.colors.highlight};
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
`;

export default Invoice;
