import React from "react";
import styled from "styled-components";
import { Text } from "../../../Widgets";

function LinePay() {
  return (
    <Wrapper>
      <Text size="sm" color="#9b9b9b">
        請使用LINE帳號或掃描行動條碼登入。若您已經是 LINE Pay
        會員，點擊付款可直接開啟應用程式進行付款
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 24px;
`;

export default LinePay;
