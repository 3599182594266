import React from "react";
import { Link } from "gatsby";
import { Block, Row, Text, RowText } from "../../Widgets";
import styled from "styled-components";

const Constants = require("../../constants");

const EXRA_ORDER_TYPE = {
  custom: "自定義商品",
  item_fee: "稿件加工",
  shipping_fee: "運費",
  construction_funds: "施工款項",
  urgent_increase: "急件加價",
};

export default function ExtraOrderSection({ order }) {
  if (!order) {
    return null;
  }

  const StyledRowText = props => (
    <RowText
      labelStyle={{ color: "#6B7280" }}
      containerStyle={{ marginBottom: "8px" }}
      {...props}
    />
  );

  return (
    <StyledBlock
      title={"補收款訂單資訊"}
      size={`${Constants.fontSize.md}px`}
      fontWeight={700}
      height={"30px"}
      lineHeight={"30px"}
      padding={"0px"}
      wrapperPadding={"24px"}
      wrapperPaddingMargin={"0 0 24px 0"}
      bg={Constants.colors.background}
      color={Constants.colors.text}
    >
      <Row style={{ marginTop: "24px" }}>
        <Link to={`/order?id=${order.base_order}`}>
          <Text size="sm" style={{ textDecoration: "underline" }}>
            原訂單資訊
          </Text>
        </Link>
      </Row>
      <StyledRowText
        weight="400"
        label="補收類別"
        value={EXRA_ORDER_TYPE[order.extra_type]}
      />
      <StyledRowText weight="400" label="補收原因" value={order.description} />
      <StyledRowText
        weight="400"
        label="補收金額"
        value={`NT$ ${order.amount}`}
      />
    </StyledBlock>
  );
}

const StyledBlock = styled(Block)`
  @media only screen and (max-width: ${Constants.breakpoints.md}px) {
    border-radius: 0;
    > * {
      padding: 0;
    }
  }
`;
