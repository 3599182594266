import React, { Fragment } from "react";
import styled from "styled-components";
import * as Cart from "../Contexts/CartContext";
import {
  DialogWrapper,
  Input,
  Button,
  Selector,
  TextArea,
  Title,
} from "../Components/DialogWidgets";
import * as Icon from "../Components/Icon";
import { Modal, Button as AntButton, message } from "antd";

const RETURN_REASON = [
  "重複下單",
  "商品款式錯誤 (例如：尺寸、顏色、圖案不符等)",
  "商品有瑕疵",
  "商品有損毀",
  "其他問題（填入備註）",
];

class ReturnApplicationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newFile: null,
      record: {
        id: null,
        order: null,
        status: "",
        note: "",
        file: "",
        item_index: 0,
        rejected_reason: RETURN_REASON[0],
      },
    };
  }

  render() {
    let { closeDialog, cartData } = this.props;
    let { record, newFile, order } = this.state;
    return (
      <StyledModal
        visible={true}
        title="售後服務申請單"
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <StyledButton onClick={() => this._createRecord()}>
              申請售後單
            </StyledButton>
          </div>
        }
        closeIcon={
          <Icon.Close
            style={{ position: "absolute", right: 20, top: 20 }}
            size={20}
            onClick={() => closeDialog()}
          />
        }
        onCancel={closeDialog}
        style={{
          textAlign: "center",
        }}
        width="720px"
      >
        <DialogWrapper>
          <div>
            <ProductName>{cartData?.items[0].name}</ProductName>

            <div className="row">
              <>
                <input
                  onClick={e => e.stopPropagation()}
                  type="file"
                  onChange={e => this.setState({ newFile: e.target.files[0] })}
                />
                <h5 style={{ color: "#009688" }}>{record.file} </h5>
              </>
            </div>
            <div className="row">
              <StyledSelector
                value={record.rejected_reason}
                onChange={e =>
                  this.setState({
                    record: { ...record, rejected_reason: e.target.value },
                  })
                }
              >
                {RETURN_REASON.map(reason => (
                  <option value={reason} key={reason}>
                    {reason}
                  </option>
                ))}
              </StyledSelector>
            </div>
            <div className="row">
              <StyledTextArea
                placeholder="補充說明問題"
                value={record.note ? record.note : ""}
                onChange={e =>
                  this.setState({ record: { ...record, note: e.target.value } })
                }
              />
            </div>
          </div>
        </DialogWrapper>
      </StyledModal>
    );
  }

  _createRecord = async () => {
    let { record, newFile } = this.state;
    let { closeDialog, appActions, orderItem, order, onUpdate } = this.props;

    try {
      await Cart.Actions.createReturnApp({
        order: orderItem.order,
        item_index: orderItem.item_index,
        status: "pending",
        note: record.note,
        file: newFile,
        rejected_reason: record.rejected_reason,
      });
      message.success("建立售後單成功");
      onUpdate();
      closeDialog();
    } catch (err) {
      message.warning("建立售後單錯誤");
      console.warn(err);
    }
  };
}

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-header {
    padding: 24px;
    border-radius: 16px 16px 0 0;
    border-bottom: none;
    .ant-modal-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
  }
  .ant-modal-body {
    padding: 0 24px 24px;
  }
  .ant-modal-footer {
    padding: 0 24px 24px;
    border-top: none;
  }
`;

const ProductName = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  text-align: left;
`;

const StyledSelector = styled.select`
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px solid #b6bac3;
  font-size: 16px;
  padding: 10px 16px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M16.59%208.59L12%2013.17%207.41%208.59%206%2010l6%206%206-6z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 24px auto;
  &:focus {
    outline: none;
  }
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  border-radius: 6px;
  border: 1px solid #dae0e6;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
`;

const StyledButton = styled(AntButton)`
  width: 112px;
  height: 56px;
  font-size: 16px;
  border-radius: 12px;
  background-color: #000;
  color: white;
`;

export default ReturnApplicationDialog;
