import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "antd";
import { Text } from "../../Widgets";

function RefundInfoForm({ initialData, onChange }) {
  const [record, setRecord] = useState({
    bank_code: initialData?.bank_code || "",
    bank_name: initialData?.bank_name || "",
    bank_account_name: initialData?.bank_account_name || "",
    bank_account: initialData?.bank_account || "",
  });

  const handleInputChange = (field, value) => {
    const updatedRecord = { ...record, [field]: value };
    setRecord(updatedRecord);
    onChange(updatedRecord);
  };

  return (
    <>
      <InputGroup>
        <Text size="xs" weight="500" css={{ marginBottom: "8px" }}>
          *轉帳銀行代碼
        </Text>
        <StyledInput
          value={record.bank_code}
          onChange={e => handleInputChange("bank_code", e.target.value)}
          placeholder="例：700（中華郵政）"
          required
        />
      </InputGroup>
      <InputGroup>
        <Text size="xs" weight="500" css={{ marginBottom: "8px" }}>
          *轉帳分行名稱
        </Text>
        <StyledInput
          value={record.bank_name}
          onChange={e => handleInputChange("bank_name", e.target.value)}
          placeholder="例：板新分行"
          required
        />
      </InputGroup>
      <InputGroup>
        <Text size="xs" weight="500" css={{ marginBottom: "8px" }}>
          *匯款戶名
        </Text>
        <StyledInput
          value={record.bank_account_name}
          onChange={e => handleInputChange("bank_account_name", e.target.value)}
          placeholder="請輸入匯款戶名"
          required
        />
      </InputGroup>
      <InputGroup>
        <Text size="xs" weight="500" css={{ marginBottom: "8px" }}>
          *匯款帳號
        </Text>
        <StyledInput
          value={record.bank_account}
          onChange={e => handleInputChange("bank_account", e.target.value)}
          placeholder="請輸入匯款帳號"
          required
        />
      </InputGroup>
    </>
  );
}

const InputGroup = styled.div`
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 8px;
  border-radius: 5px;
  border: 1px solid #b6bac3;
  padding: 12px 16px;
  margin-bottom: 0;
`;

export default RefundInfoForm;
