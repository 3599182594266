import React from "react";
import styled from "styled-components";
const constants = require("../../../constants");

function Credits({ order, credits }) {
  return (
    <Wrapper>
      {credits - order.amount < 0 && (
        <div className="tips">您的點數餘額不足，請先進行加值</div>
      )}
      <div className="container">
        <div>
          <p>點數餘額</p>
          <span>{credits} 點</span>
        </div>
        {credits - order.amount < 0 && (
          <div>
            <p>待加值</p>
            <span>{order.amount - credits} 點</span>
          </div>
        )}
      </div>
      <div className="notice">
        請前往：會員中心 > 帳戶管理 > 點數加值 > 購買點數
        (點數加值等於預先支付費用，1點＝新台幣1元。)
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .tips {
    color: ${constants.colors.highlight};
    font-weight: 500;
    margin: 8px 0 24px;
    padding-left: 24px;
  }
  & > .container {
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 500;
    padding: 24px;
    border-top: 1px solid #d8dbdf;
    border-bottom: 1px solid #d8dbdf;
    div {
      display: flex;
      align-items: center;
      flex: 0 0 50%;
      max-width: 50%;
      p {
        flex: 0 0 90px;
        max-width: 90px;
        font-weight: 500;
        margin: 0 24px 0 0;
      }
    }
  }
  & > .notice {
    color: #9b9b9b;
    padding: 24px 24px 0;
  }
`;

export default Credits;
