import React from "react";
import styled from "styled-components";
import moment from "moment";
const constants = require("../../../constants");

function CVS({ order }) {
  if (order.payment_status !== "code_generated") {
    return null;
  }
  return (
    <Wrapper>
      <div className="tips">付款成功及上傳商品印製檔案後才會正式排單審稿</div>
      <div className="container">
        <div>
          <p>繳費代碼</p>
          <span>{order.code_no}</span>
        </div>
        <div>
          <p>繳費期限</p>
          <span>{moment(order.pay_deadline).format("YYYY-MM-DD HH:mm")}</span>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .tips {
    color: ${constants.colors.highlight};
    font-weight: 500;
    margin: 8px 0 24px;
    padding-left: 24px;
  }
  & > .container {
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 500;
    padding: 24px;
    border-top: 1px solid #d8dbdf;
    border-bottom: 1px solid #d8dbdf;
    div {
      display: flex;
      align-items: center;
      flex: 0 0 50%;
      max-width: 50%;
      p {
        flex: 0 0 90px;
        max-width: 90px;
        font-weight: 500;
        margin: 0 24px 0 0;
      }
    }
  }
`;

export default CVS;
